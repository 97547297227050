<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Garden" vid="garden_search_id">
                <b-form-group
                class="row"
                label-cols-sm="12"
                label-for="garden_search_id "
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{$t('teaGardenConfig.select_garden')}}
                </template>
                <v-select name="garden_search_id"
                    :disabled="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId() ? true : false"
                    v-model="search2.garden_search_id"
                    label="text"
                    option-value="value"
                    :options= gardenList
                    :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('globalTrans.select')"
                />
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Factory" vid="factory_search_id">
                <b-form-group
                class="row"
                label-cols-sm="12"
                label-for="factory_search_id "
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{ $t('teaGarden.bought_leaf_factory') }}
                </template>
                <v-select name="factory_search_id"
                    :disabled="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId() ? true : false"
                    v-model="search2.factory_search_id"
                    label="text"
                    option-value="value"
                    :options= factoryList
                    :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('globalTrans.select')"
                />
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <b-form-group
                label-for="from_date"
                label-cols-sm="12"
                >
                <template v-slot:label>
                  {{ $t('globalTrans.start_date') }}
                </template>
                <date-picker
                  id="from_date"
                  v-model="search.from_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                >
                </date-picker>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <b-form-group
                label-cols-sm="12"
                label-for="to_date">
                <template v-slot:label>
                  {{ $t('globalTrans.to_date') }}
                </template>
                <date-picker
                  id="to_date"
                  v-model="search.to_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                >
                </date-picker>
              </b-form-group>
          </b-col>
          <b-col sm="12" class="text-right">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.stock_in_out') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
                <div class="quick-filter-wrapper">
                  <div class="quick-filter-left">
                    <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                      <b-dropdown-form>
                        <div
                          class="form-group"
                          v-for="(field, index) in labelData"
                          :key="index"
                          >
                            <b-form-checkbox
                              :id="'checkbox-' + field.labels"
                              v-model="field.show"
                              :name="'checkbox-' + field.labels"
                              value=1
                              unchecked-value=0
                            >
                              {{ $t(field.labels)  }}
                            </b-form-checkbox>
                        </div>
                      </b-dropdown-form>
                    </b-dropdown>
                  </div>
                  <div class="quick-filter-right">
                    <b-form-group
                      :label="$t('menu.perpage')"
                      label-for="per-page-select"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="search.limit"
                        :options="pageOptions"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(stock_date)="data">
                    <span v-if="data.item.stock_date">{{ data.item.stock_date | dateFormat }}</span>
                  </template>
                  <template v-slot:cell(garden_id)="data">
                    <span>{{ getGardenName(data.item.garden_id) }} <span v-if="data.item.type === 2">({{ $t('teaGarden.factory_x') }})</span></span>
                  </template>
                  <template v-slot:cell(factory_id)="data">
                    <span>{{ getFactoryName(data.item.factory_id) }}</span>
                  </template>
                  <template v-slot:cell(stock_in_quantity)="data">
                    <span v-if="data.item.stock_in_quantity > 0">{{ $n(data.item.stock_in_quantity) }}</span>
                  </template>
                  <template v-slot:cell(stock_out_quantity)="data">
                    <span v-if="data.item.stock_out_quantity > 0">{{ $n(data.item.stock_out_quantity) }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-if="data.item.status === 1" v-b-modal.modal-form variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
  </div>
</template>
<script>
import Form from './Form.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { gardenStockInOutList } from '../../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList, teaGardenService],
  components: {
    Form
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      sortBy: '',
      sortDirection: 'desc',
      sortDesc: true,
      search2: {
        garden_search_id: '',
        factory_search_id: ''
      },
      search: {
        fiscal_year_id: 0,
        garden_id: '',
        factory_id: '',
        from_date: '',
        to_date: '',
        limit: 10
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
        { labels: 'teaGardenConfig.select_garden', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
        { labels: 'teaGarden.bought_leaf_factory', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '20%' } },
        { labels: 'globalTrans.date', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '20%' } },
        { labels: 'teaGarden.stock_in_quantity_x', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: 'auto' } },
        { labels: 'teaGarden.stock_out_quantity', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.action', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: 'auto' } }
      ],
      actions: {
        edit: true,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    if (this.isGardenAdminCheckGardenId() > 0) {
      this.search2.garden_search_id = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(el => el.value === this.isGardenAdminCheckGardenId())
    }
    if (this.isFactoryAdminCheckFactoryId() > 0) {
      this.search2.factory_search_id = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(el => el.value === this.isFactoryAdminCheckFactoryId())
    }
    this.loadData()
  },
  watch: {
    'search2.garden_search_id': function () {
      if (this.search2.garden_search_id.value > 0) {
        this.search.garden_id = this.search2.garden_search_id.value
      }
    },
    currentLocale: function (newVal) {
      this.gardenList = this.gardenList.map(item => {
        return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
      })
      this.factoryList = this.factoryList.map(item => {
        return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
      })
      if (this.search2.garden_search_id) {
        const gardenObj = this.search2.garden_search_id
        this.search2.garden_search_id = Object.assign({}, gardenObj, { text: this.currentLocale === 'en' ? gardenObj.text_en : gardenObj.text_bn })
      }
      if (this.search2.factory_search_id) {
        const factoryObj = this.search2.factory_search_id
        this.search2.factory_search_id = Object.assign({}, factoryObj, { text: this.currentLocale === 'en' ? factoryObj.text_en : factoryObj.text_bn })
      }
    }
  },
  computed: {
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    factoryList () {
      return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.status === 1)
    },
    gardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'garden_id' },
          { key: 'factory_id' },
          { key: 'stock_date' },
          { key: 'stock_in_quantity' },
          { key: 'stock_out_quantity' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'garden_id' },
          { key: 'factory_id' },
          { key: 'stock_date' },
          { key: 'stock_in_quantity' },
          { key: 'stock_out_quantity' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    formTitle () {
      return this.$t('teaGarden.stock_in_out') + ' ' + this.$t('globalTrans.entry')
    }
  },
  methods: {
    getGardenName (id) {
      const objectData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
      if (typeof objectData !== 'undefined') {
          return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
        } else {
          return ''
        }
    },
    getFactoryName (id) {
      const objectData = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(item => item.value === id)
      if (typeof objectData !== 'undefined') {
          return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
        } else {
          return ''
        }
    },
    edit (item) {
      this.editItemId = item.id
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      if (this.search2.garden_search_id) {
        if (this.search2.garden_search_id.value > 0) {
          this.search.garden_id = this.search2.garden_search_id.value
          this.search.factory_id = 0
        }
      } else {
        this.search.garden_id = 0
      }
      if (this.search2.factory_search_id) {
        if (this.search2.factory_search_id.value > 0) {
          this.search.factory_id = this.search2.factory_search_id.value
          this.search.garden_id = 0
        }
      } else {
        this.search.factory_id = 0
      }
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, gardenStockInOutList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getHeadAccountTypeName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.headAccountType.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
          return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
          return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getEmployeeTypeNames (arrData) {
      var data = ''
      arrData.forEach((element, index) => {
       const obj = this.$store.state.TeaGardenService.commonObj.employeeType.find(item => item.value === element)
        if (obj) {
          this.currentLocale === 'en' ? data += obj.text_en : data += obj.text_bn
        }
        if (arrData[index + 1]) {
          data += ','
        }
      })
      return data
    }
  }
}
</script>
